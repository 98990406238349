<template>
  <div v-if="dataIsReady" id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Your Ticket'" />
    <div class="page-title-clear"></div>
    <div class="page-content">
      <!-- Ticket -->
      <CardQr
        v-for="ticket in ticketList"
        :ticket="ticket"
        :key="ticket.code"
        @openQrModal="openQrModal"
      />
      <!-- Ticket -->
      <div class="content">
        <h1>Historis Ticket</h1>
      </div>
      <CardQr
        v-for="ticket in ticketListExpired"
        :ticket="ticket"
        :key="ticket.code"
        :expired="true"
      />
      <div data-menu-load="menu-footer.html"></div>
    </div>

    <!-- Page content ends here-->
    <!-- Bahan Muncul Ticket -->
    <ModalsQR :qrCode="qrCode" :ticket="ticketDetail" />
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuOpen, menuClose } from "@/appkit.js";

import CardQr from "../components/your_ticket/CardQr.vue";
import ModalsQR from "../components/your_ticket/ModalsQR";
export default {
  components: { Header, Footer, PageTitle, CardQr, ModalsQR },
  data() {
    return {
      qrCode: "",
      ticketDetail: "",
    };
  },
  computed: {
    ticketListExpired() {
      return this.$store.getters["ticket/getUserTicketExpired"];
      /*return true;*/
    },
    ticketList() {
      return this.$store.getters["ticket/getUserTicket"];
      /*return true;*/
    },
    dataIsReady() {
      return this.ticketList && this.ticketListExpired;
      /*return true;*/
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    init_template();
  },
  methods: {
    menuOpen,
    menuClose,
    async openQrModal(code) {
      this.ticketList.map((value) => {
        if (value.code === code) {
          this.ticketDetail = value;
          this.qrCode = value.qr_code;
          return "";
        }
      });
      this.menuOpen("modal-qr-code");
    },
    async getData() {
      await this.$store.dispatch("ticket/userTicket");
    },
  },
};
</script>
