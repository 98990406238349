<template>
  <div class="card card-style mb-2">
    <div class="content">
      <div class="d-flex">
        <div>
          <img
            :src="'https://sbticket.kreya.my.id' + ticket.ticket_image"
            class="rounded-sm"
            width="100"
            height="100"
          />
        </div>
        <div class="w-100 ms-3 pt-1">
          <h6 class="font-500 font-14 pb-4">{{ ticket.ticket_title }}</h6>
          <p class="mb-0 font-11 mb-3 mt-n3 line-height-s">
            <i class="fa fa-calendar icon-20 color-blue-dark"></i>Event Time
            {{ ticket.ticket_event_date }}
          </p>
          <p class="mb-0 font-11 mb-3 mt-n3 line-height-s">
            <i class="fa fa-clock icon-20 color-blue-dark"></i>Session
            {{ ticket.ticket_session }}
          </p>
          <p class="mb-0 font-11 mb-3 mt-n3 line-height-s">
            <i class="fa fa-door-closed icon-20 color-red-dark"></i>Room
            {{ ticket.ticket_room }}
          </p>
        </div>
      </div>
      <div class="divider mt-3 mb-0"></div>
      <h6 class="font-500 font-14 pt-3">Price</h6>
      <h4 class="font-700">RP. {{ ticket.pay }}</h4>

      <div v-if="!expired" class="card-bottom pe-3 mb-3">
        <div class="float-end">
          <a
            @click="openModal(ticket.code)"
            class="btn btn-s color-white font-600 rounded-s"
            :class="{ 'gradient-red': qrDisable, 'gradient-green': !qrDisable }"
            ><span v-if="!qrDisable">View Your Ticket</span
            ><span v-else>Ticket Has been Used</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qrDisable: false,
    };
  },
  props: {
    ticket: Object,
    expired: Boolean,
  },
  emits: ["openQrModal"],
  mounted() {
    this.qrDisable = this.ticket.qr_code_status === "disable" ? true : false;
  },
  methods: {
    openModal(code) {
      console.log("click open modal");
      if (!this.qrDisable) {
        this.$emit("openQrModal", code);
      }
    },
    openDetail(slug) {
      this.$router.push({ name: "TicketInvoice" });
    },
  },
};
</script>
