<template>
  <div
    id="modal-qr-code"
    class="menu menu-box-modal rounded-m"
    data-menu-height="495"
    data-menu-width="370"
  >
    <div class="p-2">
      <div class="text-center pt-4 pb-4">
        <h2>{{ ticket.ticket_title }}</h2>

        <ul class="icon-list ps-3 font-600">
          <li>Room {{ ticket.ticket_room }}</li>
          <li>Sesi {{ ticket.ticket_session }}</li>
        </ul>

        <img
          width="250"
          class="qr-image mx-auto polaroid-effect shadow-l mb-3"
          :src="qrCode"
        />
        <ul class="icon-list ps-3 font-600">
          <li>Ticket Total :{{ ticket.seat }}</li>
          <li>Ticket Rest :{{ ticket.rest }}</li>
        </ul>
        <p class="text-center font-10 pt-2 mb-0"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ticket: Object,
    qrCode: String,
  },
  methods: {},
};
</script>
